
import { createApp } from 'vue'
import App from '../App.vue'
const app = createApp(App);
import { createRouter, createWebHashHistory} from 'vue-router'
import baseRouter from './base.router'
import mainRouter from './main.router'
import filterRouter from './router.filter'

// Vue.use(VueRouter)

//
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function (location, onResolve, onReject) {
//   if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
//   return originalPush.call(this, location).catch(err => err)
// }


const router = createRouter({
  // base: process.env.BASE_URL,
	history:createWebHashHistory(),
  // mode: 'history',
  routes:[
    ...baseRouter,
    ...mainRouter
  ],
})
filterRouter(router)
router.afterEach(() => {
  window.scrollTo(0, 0); // 切换路由之后滚动条始终在最顶部
});
export default router
