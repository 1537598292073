import { resolveComponent, ref,h } from 'vue'
export default {
  slot(obj,conf) {
    const slotText = {};
    if(conf.prepend!==''){
      slotText.prepend= (props) => { return h('span', conf.prepend)}
    }
    if(conf.append!==''){
      slotText.append= (props) => h('span', conf.append)
      //slotText.push(<template slot="append">{conf.append}</template>);
    }
    return slotText;
  }
}
