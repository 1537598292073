<script>
import draggable from 'vuedraggable'
import { VueDraggableNext } from 'vue-draggable-next';
import render from './custom/render'
import {getSimpleId} from "./utils/IdGenerate";
import {dynamicTableAllowedItems,tableAllowedItems} from "./custom/formConf";
import dynamicTable from './dynamic/dynamicTable'
import dynamicTableItem from './dynamic/dynamicTableItem'
import fancyTable from './extend/fancyTable'
import fancyTableItem from './extend/fancyTableItem'
import { h ,resolveComponent} from "vue";
/**
 * 动态表单允许增加的组件列表
 */
const components = {
  itemBtns( element,parent) {
    const {onCopyItem,onDeleteItem} = this.$attrs;
    return [
      <span class="drawing-item-copy" title="复制" onClick={event => {
      onCopyItem(element,parent); event.stopPropagation();
      }}>
      <el-icon>
        <CopyDocument />
      </el-icon>
      </span>,
      <span class="drawing-item-delete" title="删除" onClick={event => {
      onDeleteItem(element,parent); event.stopPropagation();
      }}>
      <el-icon>
        <Delete />
      </el-icon>
      </span>
    ]
  }
}
const layouts = {
  colItem( element,parent) {
    let className = this.activeItem.id === element.id ? 'drawing-item active-from-item' : 'drawing-item'
    let labelWidth = element.labelWidth ? `${element.labelWidth}px` : null
    const {onOnActiveItemChange} = this.$attrs;
    return (
        <el-col class={className} span={element.span}>
          <el-form-item label={element.showLabel ? element.label : ''}
                        label-width={labelWidth}
                        required={element.required}
                        onClick={event => {onOnActiveItemChange(element); event.stopPropagation()}}>
              <render key={element.id} conf={element}   vModel={ element.value}
                onChange={ev=>{ element.value=ev}}  onInput={ event => {
                  if(!(typeof event ==='object'))
                    element.value=event
                }}
                />
          </el-form-item>
          {components.itemBtns.apply(this, arguments)}
        </el-col>
    )
  },
  rowItem( elementModel){
    const { onOnActiveItemChange } = this.$attrs
    const className = this.activeItem.id === elementModel.id ? 'drawing-item drawing-row-item active-from-item' : 'drawing-item drawing-row-item'
    return (
        <el-col class={className} >
          <el-row  gutter={elementModel.gutter}  onClick={event => { onOnActiveItemChange(elementModel); event.stopPropagation()}}>
            <span class="component-name">{elementModel.id}</span>
            <div class="drag-wrapper" style="padding-left: 7.5px; padding-right: 7.5px;">
              {
                elementModel.columns.map((item,index) =>{
                  let children = {}
                  children.item= (elements)=>{return renderChildren.call(this,elements.element,elementModel)}
                  return h(resolveComponent("el-col"),{class:"drag-col-wrapper",span:item.span},
                    h(resolveComponent("draggable"),{class:"drag-wrapper row-drag",modelValue:item.list,"animation":100,"group":"componentsGroup",onAdd:(e)=>{
                      this.handlerAdd(e,item,elementModel)}
                      },children)
                  )
                })
              }
            </div>
          </el-row>
          {components.itemBtns.call(this,elementModel)}
        </el-col>
    )
  },
  tableItem( element){
    let className = "";
    className = this.activeItem.id === element.id ? 'drawing-item drawing-row-item active-from-item' : 'drawing-item drawing-row-item'
    const {onOnActiveItemChange} = this.$attrs;
    return (
      <div class={className}>
        <span class="component-name" style="margin-bottom:15px">{element.id}</span>
        <fancy-table  layoutArray={element.layoutArray} onClick={event => { onOnActiveItemChange(element); event.stopPropagation()}}
                      scopedSlots={{
                        default: (item) => {
                          return (
                                <draggable tag="div" class="dynamic-table__content row-drag"
                                           ghost-class="dynamicGhost" v-model={item.td.list} animation="100"
                                           group="componentsGroup"
                                           onAdd={(e) => {this.handlerTableAdd(e, item);e.stopPropagation()}}
                                >
                                    {
                                      item.td.list.map((obj)=>{
                                        return renderChildren.call(this,obj,element)
                                      })
                                    }

                                </draggable>
                          );
                        }
                      }}
        />
        {components.itemBtns.call(this,element)}
      </div>
    )
  },
  dynamicItem(element){
    let className = "";
    className = this.activeItem.id === element.id ? className+'drawing-item active-from-item' : className+'drawing-item'
    const {onOnActiveItemChange} = this.$attrs;

    let children = {}
    children.item= (elements)=>{return h(resolveComponent("dynamic-table-item"),{'item':elements.element,'activeItem':this.activeItem,
      onSelectItem:(evt,elements)=>{
        onOnActiveItemChange(elements);evt.stopPropagation()
      },onCopyItem:(evt)=>{
        this.handlerCopyItem(evt,element,elements.index);evt.stopPropagation()
      },onDeleteItem:(evt)=>{
        this.handlerDeleteItem(evt,element,elements.index);evt.stopPropagation()
      },
    })}
    console.log(element.columns)
    return h(resolveComponent("el-col"),
              {class:className},
              h(resolveComponent("dynamic-table"),
                {'conf':element,'activeItem':this.activeItem,onClick:(event)=>{onOnActiveItemChange(element); event.stopPropagation()}},
                h(resolveComponent("draggable"),
                  {'tag':'div',class:'dynamic-table__content row-drag','group':'componentsGroup','ghost-class':'dynamicGhost','item-key':'index','list':element.columns,'animation':'100',onAdd:(e)=>{this.handlerDynamicAdd(e,element)}},
                  children
                  )
                )
            )
  }
}
/**
 * 生成row的子选项
 */
function renderChildren( element,parent) {
  const layout = layouts[element.layout]
  if (layout) {
    return layout.call(this, element,parent)
  }
  return layoutIsNotFound.call(this)
}

function layoutIsNotFound() {
  throw new Error(`没有与${this.element.layout}匹配的layout`)
}
export default {
  name:"designItem",
  components: {
    render,
    draggable,
    VueDraggableNext,
    dynamicTable,
    dynamicTableItem,
    fancyTable,
    fancyTableItem
  },
  props: {
    model: {
      type: Object,
      default:{}
    },
    activeItem: {
      type: Object,
      default:{}
    }
  },
  data(){
    return {
      activeComponent:this.activeItem
    }
  },
  render() {
    const layout = layouts[this.model.layout]
    if (layout) {
      return layout.call(this,  this.model)
    }
    return layoutIsNotFound.call(this)
  },
  methods:{
    handlerAdd(evt,item,row){
      if(evt.pullMode === 'clone'){
        if(!(evt.to.className.indexOf('row-drag')>-1&&this.activeItem.compType==='row')){
          item.list.splice(evt.newIndex,0,this.activeItem);
        }
      }else{
        if(evt.item.className.indexOf('el-row')>-1){  //防止row嵌套
          const newIndex = evt.newIndex;
          const oldIndex = evt.oldIndex;
          const rowItem = item.list[newIndex];
          item.list.splice(newIndex,1);
          this.$message.error('布局组件不允许被嵌套！');
          this.$emit('rowItemRollBack',rowItem,oldIndex);  //还原到原先的列表中
          return false;
        }
      }
    },
    /**
     * 动态表单
     */
    handlerDynamicAdd(evt,item){
      if(evt.pullMode === 'clone'){
        if(dynamicTableAllowedItems.includes(this.activeItem.compType)){
          item.columns.splice(evt.newIndex,0,this.activeItem);
          console.log(item.columns)
        }else{
          this.$message.error('该组件不允许被放入动态表格内！');
        }
      }else{
        if(evt.item.className.indexOf('el-row')>-1){  //防止row嵌套
          const newIndex = evt.newIndex;
          const oldIndex = evt.oldIndex;
          const rowItem = item.columns[newIndex];
          item.columns.splice(newIndex,1);
          this.$message.error('布局组件不允许放入动态表格！');
          this.$emit('rowItemRollBack',rowItem,oldIndex);  //还原到原先的列表中
          return false;
        }
      }
    },
    handlerTableAdd(evt,item){
      if(evt.pullMode === 'clone'){
        if(tableAllowedItems.includes(this.activeItem.compType)){
          item.list.splice(evt.newIndex,0,this.activeItem);
        }else{
          this.$message.error('该组件不允许被放入表格内！');
        }
      }
    },
    handlerCopyItem(evt,element,index){
      const item = element.columns[index];
      const clone = JSON.parse(JSON.stringify(item))
      let uId = "fd_"+getSimpleId();
      clone.id = uId;
      clone._id = uId;
      clone.name = uId;
      element.columns.push(clone);
    },
    handlerDeleteItem(evt,element,index){
      element.columns.splice(index,1);
    }
  }
}
</script>
