<template>
  <el-form
    ref="form"
    v-bind="computedConfig"
    :rules="rules"
    :model="data"
  >
    <el-row :gutter="20">
      <el-col
        v-for="(item, index) in computedItems"
        :key="getItemKey(item, index)"
        :md="item.md"
        :sm="item.sm"
      >
<!--        sm:≥768px 响应式栅格数或者栅格属性对象-->
<!--        md	≥992px 响应式栅格数或者栅格属性对象-->
        <el-form-item :label="item.label + ':'" :prop="item.prop" v-if="item.show!=false">
          <render-content
            slot="label"
            v-if="item.labelRender"
            :render="item.labelRender"
            :data="item"
          ></render-content>
          <slot :name="item.slot" v-bind="{ item }">
            <component
              v-if="item.component"
              :ref="item.ref || `cp-${item.prop}`"
              :is="item.component"
              :data="data"
              v-model="data[item.prop]"
              v-bind="item.props"
              v-on="item.listeners"
            />
            <span v-else>{{data[item.prop]}}</span>
          </slot>
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
  import EditForm from './js/EditForm'
  export default {
    ...EditForm
  }
</script>
<style lang='less' scoped>
  :deep(.el-select) {
    width: 100%;
  }
</style>
