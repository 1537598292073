
<template>
<div>
    <el-form :model="phoneForm" :rules="phonerules" ref="phoneForm" label-width="60px" class="login-phoneForm">
            <el-row >
                <el-col :span="20">
                    <el-form-item prop="phoneNumber">
                        <el-input placeholder="手机号" prefix-icon="el-icon-mobile" v-model="phoneForm.phoneNumber">
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="14">
                    <el-form-item prop="Pnumber">
                        <el-input class="borderInput" placeholder="验证码" v-model="phoneForm.Pnumber">
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                  <el-button class="tabsPsd_btn" type="primary" style="border-radius: 0px 4px 4px 0;">获取验证码</el-button>
                </el-col>
            </el-row>
            <el-row >
                <el-col :span="19" :offset="3">
                  <el-button type="primary" class="tabsPhone_btn">登录</el-button>
                </el-col>
            </el-row>

        </el-form>
</div>
</template>

<script>
  import elTabsPhone from './js/elTabsPhone'
  export default {
    ...elTabsPhone
  }
</script>
<style lang='less' scoped>
  @import "./css/elTabsPhone.less";
</style>
