import md5 from 'md5';
import user from '../../../../../api/user.js';
import Verify from "@/components/verifition/Verify";
import {log} from "echarts/lib/util/log";
import { Avatar, Lock } from "@element-plus/icons-vue";
export default {
  components: {
    Verify
  },
  data() {
    return {
	  Avatar,
	  Lock,
      forgetChecked: false,
      typepsd: "password",
      flag: true,
      imgUrl:this.BASEPATH+"/base/authoriza/user/imgcode.htm?d="+new Date().getTime(),
      imgsrc: require("@/assets/images/denglu_ic_biyan.png"),
      ruleForm: {
        name: "",
        password: "",
        // number: "",
      },
      rules: {
        name: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          { min: 3,  message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 4,  message: "长度在 3 到 5 个字符", trigger: "blur" },
        ],
        // number: [
        //   { required: true, message: "请输入验证码", trigger: "blur" },
        //   { min: 4,  message: "请输入4位验证码", trigger: "blur" },
        // ],
      },
    };
  },
  computed: {},
  watch: {},
  //创建成功后来定义江畔事件//
  created () {
    document.onkeydown = (e) => {
      if (window.event === undefined) {
        var key = e.keyCode
      } else {
        // eslint-disable-next-line no-redeclare
        var key = window.event.keyCode
      }
      if (key === 13) {
        this.submitForm()
      }
    }
    this.getStorage();
  },
  methods: {
    success(data){
      let this_=this;
      // data 返回的二次验证参数
      let param = this.buildingParams();
      param.imgCode = data.captchaVerification
      if(param){
        user.getTimestamp().then(res=>{
          var time = res[2];
          param.date=time;
          param.password=md5(md5(param.password).toUpperCase()+param.date).toUpperCase();
          const self = this;
          user.userLogin(param).then(res=>{
            if (self.forgetChecked == true) {
              self.setStorage(self.ruleForm.name, self.ruleForm.password, 7);
            }else {
              self.clearStorage();
            }
            if(res[0]==0){
              this_.$store.state.tabsPage = [
                {title:'欢迎',name:'desk'},
              ];
              this_.$store.state.TabsValue = '/base/main/desk/desk';
              this_.$store.state.noticeShow = true;
              this_.$router.push({
				// path:"/index",
				      path: "/base/main/desk/desk"
			});
            }else{
              this_.$message.warning(res[1])
            }
          })
        })
        // //当mode="pop"时,调用组件实例的show方法显示组件
        // this_.$refs.verify.show();
      }
    },
    buildingParams() {
      let params = {
        userCode: this.ruleForm.name,
        password: this.ruleForm.password,
        // imgCode:  this.ruleForm.number
        imgCode: ""
      }
      return params;
    },
    handleclose() {
      this.flag = !this.flag;
      if (this.flag) {
        this.typepsd = "password";
        this.imgsrc = require("@/assets/images/denglu_ic_biyan.png");
      } else {
        this.typepsd = "text";
        this.imgsrc = require("@/assets/images/denglu_ic_zhangkai.png");
      }
    },
    handleForget() {
      this.$router.push('/base/main/forgetPsd/forgetPsd');
    },
    submitForm(formName) {
      if(this.ruleForm.name.trim() == "") {
        this.$message.error('请输入账号');
        return
      }else if(this.ruleForm.password.trim() == "") {
        this.$message.error('请输入密码');
        return
      }
      this.$refs.verify.show();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // changeImg(){
    //   this.imgUrl=this.BASEPATH+"/base/authoriza/user/imgcode.htm?d="+new Date().getTime();
    //   this.ruleForm.number=""
    // },
    //设置Storage
    setStorage(c_name, c_pwd, exdays) {
      var exdate = new Date(); //获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
      //字符串拼接cookie
      localStorage.setItem('name',c_name);
      localStorage.setItem('password',c_pwd);
    },
    //读取Storage
    getStorage: function() {
      if (localStorage.getItem('name')) {
        this.ruleForm.name = localStorage.getItem('name');
        this.ruleForm.password = localStorage.getItem('password');
        this.forgetChecked = true;
      }
    },
    //清除Storage
    clearStorage: function() {
      localStorage.removeItem('name');
      localStorage.removeItem('password');
    }

  }
};

