import tabsPsd from '../el_tabs_password';
import tabsPhone from '../el_tabs_phone';
import Storage from "@/commons/utils/storage.js"
export default {
  components: {
    tabsPsd,
    tabsPhone,
  },
  data() {
    return {
      activeName: "first",
    };
  },
  computed: {},
  watch: {},
  created() {
    this.USERROLE.clear()
    Storage.remove("menu");
    Storage.remove("isMenu");
    Storage.remove("user")
  },
  methods: {

  },
};
