
import Storage from '@utils/storage'
import isEmpty from 'lodash/isEmpty';
import Ajax from './Ajax';
import http from "@/commons/utils/ajax.js";


const getTimestamp = () => http.ajax({url:`/base/authoriza/login/getTimestamp?`+new Date().getTime()});
const userLogin =param => http.ajax({url:`base/authoriza/login/userLoginWeb`,data:param});

export default {
  getTimestamp,
  userLogin
}
