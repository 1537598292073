import {isAttr,jsonClone} from '../utils/index';
import childrenItem from './slot/index';
import { h ,resolveComponent } from "vue";
import {remoteData} from './mixin';
function vModel(self, dataObject) {
  dataObject.onInput = (val) => {
    self.$emit('input', val)
  }
}

export default {
   render() {
    let dataObject = {
      //attrs: {},
      //props: {},
      //on: {},
      style: {}
    }
    //远程获取数据
    this.getRemoteData();
    const confClone = jsonClone(this.conf);
    const children = childrenItem(this,confClone);
    Object.keys(confClone).forEach(key => {
      const val = confClone[key]
      if (dataObject[key]) {
        dataObject[key] = val
      } else if (!isAttr(key)) {
        dataObject[key] = val
      } else if(key ==='width'){
        dataObject.style= 'width:'+val;
      }else {
        dataObject[key] = val
      }
    })
    /*调整赋值模式，规避cascader组件赋值props会出现覆盖预制参数的bug */
    vModel(this, dataObject);
     let obj = resolveComponent(confClone.ele);
     if(children.length==0){
       return h(obj,dataObject)
     }
    return h(obj,dataObject,children[0])
  },
  props: ['conf'],
  mixins:[remoteData]
}
