
<template>
  <div class="login_bck">
    <div class="login_content">
      <div class="login_content_inner">
<!--        <img src="~@/assets/images/logo.png" style=" height:100px;margin: 0 auto;display: block;padding-top: 50px" alt="">-->
        <h1 >
          渭南公共文化云<br>管理系统
        </h1>
<!--        <el-tabs v-model="activeName">-->
<!--          <el-tab-pane label="" name="first">-->
            <tabsPsd ref="tabsPsd" />
<!--          </el-tab-pane>-->
          <!--<el-tab-pane label="手机" name="second">
            <tabsPhone ref="tabsPhone" />
          </el-tab-pane>-->
<!--        </el-tabs>-->
<!--        <div class="regest">-->
          <!-- 立即注册 -->
          <!-- <span>还没有账号？<a class="target">立即注册</a></span> -->
<!--        </div>-->
      </div>
    </div>
    <!--<div class="footer">
      <div>智慧物业管理</div>
      <div>
        备案号:京ICP备16017530号-1 版权商标所有“®”,请勿侵权 Copyright ©
        2012-2017. All Rights Reserved
      </div>
    </div>-->
  </div>
</template>

<script>
  import login from './js/login'
  export default {
    ...login
  }
</script>
<style lang='less' scoped>
  @import "./css/login.less";
</style>
