import { isAttr,jsonClone } from '../utils';
import childrenItem from './slot/index';
import {remoteData} from './mixin';
import { h,resolveComponent } from "vue";
//先修改在这里,后续需要优化
function vModel(self, dataObject) {
  // debugger
  if(!!self.value){
    dataObject.value = self.value;
  }
  dataObject.onInput = val => {
    self.$emit('input', val)
  }
  //判断是否为上传组件
  if(self.conf.compType === 'upload'){
    dataObject['before-upload'] = file=>{
      //非限定后缀不允许上传
      const fileName = file.name;
      const suffixName = fileName.split('.').pop();

      if(!self.conf.accept.includes(suffixName)){
        self.$message.error('该后缀文件不允许上传');
        return false;
      }
      const fileSize = file.size;
      if(fileSize>dataObject.props.fileSize*1024*1024){
        self.$message.error('文件大小超出限制，请检查！');
        return false;
      }
    }
  }
}

export default {
  render() {
    let dataObject = {
      // attrs: {},
      // props: {},
      // on: {},
      style: {}
    }
    //远程获取数据
    this.getRemoteData();
    const confClone = jsonClone(this.conf);
    const children = childrenItem(this,confClone);
    Object.keys(confClone).forEach(key => {
      const val = confClone[key]
      if (dataObject[key]) {
        dataObject[key] = val
      } else if(key ==='width'){
        dataObject.style= 'width:'+val;
      } else if (!isAttr(key)) {
        dataObject[key] = val
      }else {
        if(key !== 'value'){
          dataObject[key] = val
        }
      }
    })
    debugger
    vModel(this, dataObject);
    let obj = resolveComponent(confClone.ele);
    if(children.length==0){
      return h(obj,dataObject)
    }
    return h(obj,dataObject,children[0])
  },
  props: ['conf','value'],
  mixins:[remoteData]
}
