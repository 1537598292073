
import Storage from "@/commons/utils/storage.js"

// import Vue from "vue";
const mainRouter = [
  {
    path: '/login',
    component: () => import('../views/base/main/login/Login.vue')
  },{
    path:'/index',
    component: () => import('../views/base/main/home/Home.vue'),
    children: [
      {
        path: '/',
        name:'desk',
        redirect:'/base/main/desk/desk'
      },{
        path: '/base/main/desk/desk',
      name:'desk',
        component: () => import('../views/base/main/desk/desk.vue')
      }
    ]
  },{
  path:'/base/main/forgetPsd/forgetPsd',
    name:'forgetPsd',
    component: () => import('../views/base/main/forgetPsd/forgetPsd.vue'),
},{
    path:'/processDesigner',
    name:'processDesigner',
    component: () => import('../views/process/processDesigner.vue'),
  },{
    path:'/statistics',
    name:'statistics',
    component: () => import('../views/pc/statistics/dataStatistics.vue'),
  },{
  path:'/designer',
    name:'designer',
    component: () => import('../components/formDesigner.vue'),
},{
    path:'/',
    component: () => import('../views/culture/index/index.vue'),
    redirect: '/home',
    children: [
      {
        path: '/home',
        name:'home',
        component: () => import('../views/culture/home/home.vue')
      },
      {
        path: '/culture/information',
        name:'information',
        component: () => import('../views/culture/information/information.vue')
      },
      {
        path: '/culture/details',
        name:'details',
        component: () => import('../views/culture/details/details.vue')
      },
      {
        path: '/culture/mine',
        name:'mine',
        component: () => import('../views/culture/mine/mine.vue')
      },
      {
        path: '/culture/bookVenues',
        name:'bookVenues',
        component: () => import('../views/culture/bookVenues/bookVenues.vue')
      },
      {
        path: '/culture/detailsVenues',
        name:'detailsVenues',
        component: () => import('../views/culture/details/detailsVenues.vue')
      },
      {
        path: '/culture/watchLive',
        name:'watchLive',
        component: () => import('../views/culture/watchLive/watchLive.vue')
      },
      {
        path: '/culture/detailLive',
        name:'detailLive',
        component: () => import('../views/culture/details/detailLive.vue')
      },


      {
        path: '/culture/weinanOpera',
        name:'weinanOpera',
        component: () => import('../views/culture/weinanOpera/weinanOpera.vue')
      },
      {
        path: '/culture/detailsOpera',
        name:'detailsOpera',
        component: () => import('../views/culture/details/detailsOpera.vue')
      },


      {
        path: '/culture/villageTravel',
        name:'villageTravel',
        component: () => import('../views/culture/villageTravel/villageTravel.vue')
      },
      {
        path: '/culture/detailsTravel',
        name:'detailsTravel',
        component: () => import('../views/culture/details/detailsTravel.vue')
      },
      {
        path: '/culture/learnSkill',
        name:'learnSkill',
        component: () => import('../views/culture/learnSkill/learnSkill.vue')
      },
      {
        path: '/culture/detailSkill',
        name:'detailSkill',
        component: () => import('../views/culture/details/detailSkill.vue')
      },
      {
        path: '/culture/readBook',
        name:'readBook',
        component: () => import('../views/culture/readBook/readBook.vue')
      },
      {
        path: '/culture/detailsRead',
        name:'detailsRead',
        component: () => import('../views/culture/details/detailsRead.vue')
      },
      {
        path: '/culture/enjoyActivite',
        name:'enjoyActivite',
        component: () => import('../views/culture/enjoyActivite/enjoyActivite.vue')
      },
      {
        path: '/culture/detailsActive',
        name:'detailsActive',
        component: () => import('../views/culture/details/detailsActive.vue')
      },
      {
        path: '/culture/enjoyAssociation',
        name:'enjoyAssociation',
        component: () => import('../views/culture/enjoyAssociation/enjoyAssociation.vue')
      },
      {
        path: '/culture/detailsAss',
        name:'detailsAss',
        component: () => import('../views/culture/details/detailsAss.vue')
      },

      {
        path: '/culture/shortVideo',
        name:'shortVideo',
        component: () => import('../views/culture/shortVideo/shortVideo.vue')
      },
      {
        path: '/culture/detailShortVideo',
        name:'detailShortVideo',
        component: () => import('../views/culture/details/detailShortVideo.vue')
      },
    {
      path: '/culture/riversMountain',
        name:'riversMountain',
      component: () => import('../views/culture/riversMountain/riversMountain.vue')
    },
    {
      path: '/culture/commonCulture',
        name:'commonCulture',
      component: () => import('../views/culture/commonCulture/commonCulture.vue')
    },
      {
        path: '/culture/brandActivite',
        name:'brandActivite',
        component: () => import('../views/culture/brandActivite/brandActivite.vue')
      },
      {
        path: '/culture/detailsMountain',
          name:'detailsMountain',
          component: () => import('../views/culture/details/detailsMountain.vue')
      },
      {
        path: '/culture/search',
          name:'search',
          component: () => import('../views/culture/search/search.vue')
      }
    ]
  },
]
  let menu = Storage.get("menu");
  if(menu){
    menu = Storage.formatTreeData(menu,'0').children;
    mainRouter.forEach((val,index)=>{
      if(val.path=="/index"){
        let tmp=[];
        Storage.getDataRouter(menu,tmp);
        tmp = val.children.concat(tmp);
        val.tmp = val.children;
        val.children=tmp;
      }
    });
  }
export default mainRouter
