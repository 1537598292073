<template>
  <div class="right-board">
    <el-tabs v-model="currentTab" class="center-tabs">
      <el-tab-pane label="属性配置" name="field" >
        <div class="field-box">
          <el-scrollbar class="right-scrollbar">
            <el-form size="small" label-width="100px" >
              <div v-for="(item,index) in cmps" :key="index">
                <component v-if="item.name === activeItem.compType" :getFormId="getFormId" :getFormKey="getFormKey" :props="activeItem" :is="item.content"></component>
              </div>
            </el-form>
          </el-scrollbar>
        </div>
      </el-tab-pane>
      <el-tab-pane label="表单配置" name="second">
        <el-form style="padding: 20px" label-width="100px" :rules="formRules" label-position="top" :model="formItemConf">
          <el-form-item label="表单分类" prop="formType">
            <el-select v-model="formItemConf.formType" placeholder="请选择分类">
              <el-option label="Zone one" value="shanghai" />
              <el-option label="Zone two" value="beijing" />
            </el-select>
          </el-form-item>
          <el-form-item label="表单名称" prop="formName">
            <el-input type="text" show-word-limit maxlength="50" v-model="formItemConf.formName"></el-input>
          </el-form-item>
          <el-form-item label="表单别名" prop="formAlias">
            <el-input type="text" show-word-limit maxlength="50" v-model="formItemConf.formAlias"></el-input>
          </el-form-item>
          <el-form-item label="表单描述">
            <el-input type="textarea" show-word-limit maxlength="200" :autosize="{ minRows: 2, maxRows: 4 }" v-model="formItemConf.formDescribe"></el-input>
          </el-form-item>
        </el-form>

      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import reg from "./custom/register";
export default {
  name:'configPanel',
  data() {
    return {
      formRules:{
        formType: [
          { required: true, message: '请选择分类', trigger: 'change' },
        ],
        formName: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        formAlias: [
          { required: true, message: '请输入别名', trigger: 'blur' },
        ],
      },
      currentTab: 'field',
      cmps:reg,
      formIdArray:[]
    }
  },
  props:{
    formItemConf:{
      type: Object,
      default:function(){
        return {}
      }
    },
    activeItem: {
      type: Object,
      default:function(){
        return {}
      }
    },
    itemList: {
      type: Array,
      default:function(){
        return []
      }
    }
  },
  created() {
    this.cmps.forEach(c => {
      c.content = require(`./custom/configs/${c.name}`).default;
    });
  },
  methods:{
    getFormKey(id,val){
      let isOnce=false;
      Array.from(this.itemList,(item)=>{
          if(item.id!=id){
            if(item.name==val){
              isOnce= true;
              return true;
            }
          }
      })
      return isOnce;
    },
    getFormId(itemId){
      this.formIdArray = [];
      Array.from(this.itemList,(item)=>{
        if(item.compType === 'row'){
          Array.from(item.columns,(column)=>{
            Array.from(column.list,(col)=>{
              if(col._id !== itemId){
                this.formIdArray.push(col.id);
              }
            })
          })
        }else{
          if(item._id !== itemId){
            this.formIdArray.push(item.id);
          }
        }
      })
      return this.formIdArray;
    }
  }
}
</script>
<style scoped>
.field-box >>> .el-scrollbar__wrap{
  overflow-x: hidden;
}
</style>
