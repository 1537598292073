
<template>

  <div class="upload-container">
    <el-dialog width="50%"  v-model="dialogVisibleImg" :title="ImgName"  append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <el-upload :class="[disabledInput? 'upLoadShowNone':'']"
               class="upload-demo"
               :id="id"
               :accept="accept"
               :action="UploadUrl()"
               :multiple="multiple"
               :on-remove="handleRemove"
               :on-success="handleSuccess"
               :on-preview="handlePictureCardPreview"
               show-file-list
               :before-upload="beforeAvatarUpload"
               :disabled="disabledInput"
               :limit="limit"
               :on-exceed="onExceed"
               :file-list.sync="fileList">
<!--      <el-button :size="size" :type="type"  icon="el-icon-upload" :plain="plain">-->
<!--        {{btnMSg}}-->
<!--      </el-button>-->
      <i ref="btnAdd" class="el-button el-button--default" style="font-style: normal;">上传</i>

<!--      <i :class="[limit>=fileList.length?'avatar-uploader-icon':'el-icon-plus'] "></i>-->
<!--      <i class="el-icon-plus"></i>-->
      <!--<el-button type="text" type="primary" >点击上传</el-button>-->
<!--      <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>-->
    </el-upload>
  </div>
</template>

<script>
  import UploadFile from './js/UploadFile'
  export default {
    ...UploadFile
  }
</script>

<style lang="less" scoped>

  .upload-container {
    .el-upload{
      text-align: left;
    }
  }
</style>
