import formDesigner from '@/components/formDesigner';
import fancyEditor from '@/components/extend/fancyEditor';
import fancyHtml from '@/components/extend/fancyHtml';
import fancyText from '@/components/extend/fancyText';
import fancyBarCode from '@/components/extend/fancyBarCode';
import fancyDialogList from '@/components/extend/fancyDialogList';
import iconDialog from '@/components/iconDialog';

import {defineComponent, defineAsyncComponent} from 'vue';
const plugins = {
  install  (app) {
    app.component('form-designer',formDesigner);
    app.component('fancy-editor',fancyEditor);
    app.component('fancy-html',fancyHtml);
    app.component('fancy-text',fancyText);
    app.component('fancy-bar-code',fancyBarCode);
    app.component('fancy-dialog-list',fancyDialogList);
    app.component('icon-dialog',iconDialog);
  }
};
window.plugins=plugins;
// 这一步判断window.Vue是否存在，因为直接引用vue.min.js， 它会把Vue绑到Window上，我们直接引用打包好的js才能正常跑起来。
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(plugins);
}
export default plugins;
