export default {
  name: 'UploadFile',
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    uploadId:{
      type: String
    },
    disabledInput: {
      type: Boolean,
      default: true
    },
    fileList: {
      type: Array,
      default:function() {
        return []
      }
      },
    accept: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'small'
    },
    type: {
      type: String,
      default: 'primary'
    },
    btnMSg: {
      type: String,
      default: '点击上传'
    },
    plain: {
      type: Boolean,
      default: true
    },
    limit:{
      type: Number,
      default: 1
    },
    beforeAvatarUpload:{
      type:Function,
      default:function (file) {
        let accept=this.accept;
        if(accept!="" && accept!=undefined){
            if(file.type.indexOf(accept.substr(0,accept.indexOf("/")))==-1){
              this.$message.error('文件类型错误');
              return false;
            }
        }
        // const isJPG = file.type == 'image/jpeg';
        const isLt2M = file.size / 1024 / 1024 < 2;

        // if (!isJPG) {
        //   this.$message.error('上传头像图片只能是 JPG 格式!');
        // }
        if (!isLt2M) {
          this.$message.error('上传头像图片大小不能超过 2MB!');
        }
        return isLt2M;
      }
    }
  },
  data() {
    return {
      dialogVisibleImg:false,
      ImgName:'',
      dialogImageUrl:'',
      btnObject:undefined
    }
  },
  computed: {
  },
  created:function(){
    this.id = this.uploadId || 'upload_' + Math.random().toString(16).slice(-6);
    if(this.fileList&&this.fileList.length>0){
      if(this.fileList.length>=this.limit){
        if(this.$refs.btnAdd){
          this.btnObject= this.$refs.btnAdd.parentElement;
          this.btnObject.style.display="none";
        }
      }
    }else{
      this.handleRemove(null,[]);
    }
  },
  methods: {
    //上传路径
    UploadUrl:function(){
      // return "http://192.168.199.178:740/base/fus/uploadFile";
      return this.BASEPATH+'/base/authoriza/fileup/upload';
    },
    onExceed:function(files, fileList){
    },
    handleSuccess(res, file,fileList) {
      if(fileList.length>=this.limit){
        this.btnObject= this.$refs.btnAdd.parentElement;
        this.btnObject.style.display="none";
      }
      this.$emit('update:fileList', fileList,this.uploadId)
    },
    handleRemove(file, fileList) {
      if(this.btnObject){
        this.btnObject.style.display="inline-block";
      }
      this.$emit('update:fileList', fileList,this.uploadId)
    },
    handlePictureCardPreview(file) {
      let contentType=''

      if(file.contentType){
        contentType = file.contentType.split('/')[0];
      }else{
        if(file.raw.type){
          contentType = file.raw.type.split('/')[0];
        }else{
          contentType = file.response.body.contentType.split('/')[0];
        }

      }

      if(contentType=='image'){
        this.dialogImageUrl = file.url;
        this.dialogVisibleImg = true;
        this.ImgName = file.name;
      }
      else{
        window.open(file.url);
      }
    },
  }
}
