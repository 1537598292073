
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axiosConfig from './api/axios.config'
//  引入 element
import 'element-plus/theme-chalk/index.css';
import ElementPlus from 'element-plus';
import locale from 'element-plus/dist/locale/zh-cn';
import axios from "axios";
import store from './store';
// import './plugins/element.js'
import http from  './commons/utils/ajax';
import './commons/css/common.css';
import commonTable from "@/components/table/commonTable";
import EditForm from '@/components/EditForm/EditForm';
import print from 'vue3-print-nb';
import { ElMessage } from 'element-plus';
import MyPD from "@/package/index.js";
import * as ELIcons from '@element-plus/icons'
import "@/package/theme/index.scss";

import './utils/rem.js';
import './assets/iconfont/iconfont.js'
import plugins from './components/index';
/*左边工具栏以及编辑节点的样式*/
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-codes.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn-embedded.css';
/*右边工具栏样式*/
import 'bpmn-js-properties-panel/dist/assets/bpmn-js-properties-panel.css';


import VueHighlightJS from "vue3-highlightjs";
import "highlight.js/styles/atom-one-dark-reasonable.css";
import Storage from "@utils/storage";
const app = createApp(App);
if(window.plugins){
  app.use(window.plugins)
}
for (let iconName in ELIcons) {
  app.component(iconName, ELIcons[iconName])
}
app.use(VueHighlightJS);
app.use(ElementPlus, { locale })
app.use(router);
app.use(store);
app.use(MyPD);
axiosConfig(router)
app.use(print)
app.config.globalProperties.productionTip = false;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.BASEPATH = process.env.VUE_APP_API_BASEURL;//后台代理访问前缀
window.BASEPATH = process.env.VUE_APP_API_BASEURL;
app.config.globalProperties.PRODUCT_PATH=process.env.VUE_APP_PRODUCT_PATH;//前端页面访问相对路径
app.provide('$message', ElMessage)
window.PRODUCT_PATH = process.env.VUE_APP_PRODUCT_PATH;
app.config.globalProperties.http =http;
app.config.globalProperties.USERROLE = new Set();
app.config.globalProperties.currentTabName = "";
app.config.globalProperties.productionTip = true;
app.config.globalProperties.devtools = "";
app.component('commonTable',commonTable);
app.component('EditForm',EditForm);

app.mixin({
  mounted: function () {
    if(this.$el.dataset){
      let url = this.$el.dataset.url;
      if(!!url&&this.$options.name=='ElButton'){
        let user = JSON.parse(decodeURIComponent(decodeURIComponent(Storage.get("user"))));
        let isadmin =user.roles.filter((item)=>item.roleId=='221213170306000002')
        if(!this.USERROLE.has(url)){
          this.$el.style.display="none";
        }
        if(isadmin.length>0){
          if(this.$el.innerText=='编辑'){
            this.$el.style.display="inline-flex";
          }
        }
      }
    }
  }
})
app.mount('#app');

