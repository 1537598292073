/**
 * 单行&多行输入框
 */
export let rangeTime = {
  id:'',
  _id:'',
  compType: 'rangeTime',
  ele: 'el-time-picker',
  //控件名称(左侧显示)
  compName:'时间范围',
  //图标
  compIcon:'time',
  //展示表单的模式
  viewType:'text',
  // 是否可配置
  //暂时默认为true，无法更改，后期考虑某些时候无法配置
  config: true,
  // 控件左侧label内容
  showLabel:true,
  label: '时间范围',
  labelWidth: '80',
  'start-placeholder':"开始时间",
  'end-placeholder':"结束时间",
  // 是否必填
  required: false,
  //栅格间隔
  gutter:15,
  //默认栅格
  span:24,
  //是否可清空
  clearable:true,
  disabled:false,
  readonly:false,
  value: '',
  rules:[],
  'arrow-control':true,
  align:'left',
  format: 'HH:mm:ss',
  'is-range':true,
  'range-separator':'-'
}
