// 模态框
export const remoteData = {
    methods: {
        getRemoteData(){
            //动态数据
            if(this.conf.dataType === 'dynamic'){
              if(this.conf.action.includes('://')){
                this.$axios.get(this.conf.action).then(res => {
                  if(this.conf.options.length===0){
                    this.conf.options = this.conf.options.concat(res.data);
                  }
                })
              }else{
                var this_ = this;
                this.http.ajaxHandler().success(function (code, msg, data) {
                  if (code == 0) {
                    if(this_.conf.options.length===0){
                      this_.conf.options = this_.conf.options.concat(data);
                    }
                  }
                }).error(function (code, msg) {
                  this_.$message.error(msg);
                }).post({ url: this_.conf.action });
              }
            }
        }
    }
}

export const changeId = {
    methods: {
      handlerChangeId(val){
        let idArray = this.getFormId(this.props._id);
        console.log(idArray);
        if(idArray.includes(val)){  //如果存在id相等，则提示
          this.$message.error('该ID已经存在，请修改');
          this.props.id=this.props._id;
        }else{
          this.props._id=val;
        }
      }
    }
}

export  const changeKey ={
  methods:{
    handlerChangeKey(val,id){
      let key = this.getFormKey(id,val);
      if(key){
        this.$message.error('该KEY已经存在，请修改');
      }
    }
  }
}




