<template>
  <div class="barcode_container">
    <img :id="id" />
  </div>
</template>

<script>
  import JsBarcode from 'jsbarcode'
  export default {
    components:{
      JsBarcode
    },
    props:{
      value:{
        type:String,
        default:''
      },
      id:{
        type:String,
        default:''
      },
      background:{
        type:String,
        default:''
      },
      lineColor:{
        type:String,
        default:''
      },
      format:{
        type:String,
        default:''
      },
      width:{
        type:Number,
        default:600
      },
      height:{
        type:Number,
        default:0
      },
      displayValue:{
        type:Boolean,
        default:false
      },
    },
    data() {
      return {
      }
    },
    methods: {
      initBarCode(value){
        if(!!value){
          JsBarcode('#'+this.id, value, {
            format: this.format,
            lineColor: this.lineColor,
            background:this.background,
            width: 2,
            height: this.height,
            displayValue: this.displayValue
          })
        }
      }
    },
    mounted() {
        this.initBarCode(this.value)
    },

    watch:{
      value(newVal){
        this.initBarCode(newVal)
      },
      background(newVal){
        this.initBarCode(this.value)
      },
      lineColor(newVal){
        this.initBarCode(this.value)
      },
      format(newVal){
        this.initBarCode(this.value)
      },
      width(newVal){
        this.initBarCode(this.value)
      },
      height(newVal){
        this.initBarCode(this.value)
      },
      displayValue(newVal){
        this.initBarCode(this.value)
      }
    }

  }
</script>

<style>
</style>
